export default {
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    model: {
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: [String, Boolean],
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    autocapitalize: {
      type: String,
      default: 'off'
    },
    autocorrect: {
      type: String,
      default: 'off'
    },
    pattern: {
      type: [String, Boolean],
      default: false
    },
    dontShowErrorMsg: {
      type: Boolean,
      default: false
    },
    autoTab: {
      type: String,
      default: ''
    }
  }
};
