<template>
  <div class="field" :class="classes">
    <div class="field__wrapper">
      <span v-if="title" class="field__title-wrapper">
        <span class="field__title">
          {{ title }} <span v-if="subtitle">{{ subtitle }}</span>
        </span>
      </span>
      <input
        v-if="!textarea"
        ref="field"
        class="field__element"
        :disabled="disabled"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :autocorrect="autocorrect"
        :pattern="computedPattern"
        :readonly="readonly"
        :inputmode="inputMode"
        @input="inputEvent"
        @focus="focusEvent"
        @blur="blurEvent"
      />
      <textarea
        v-else
        ref="field"
        class="field__element"
        :disabled="disabled"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :autocorrect="autocorrect"
        :pattern="computedPattern"
        :readonly="readonly"
        :inputmode="inputMode"
        @input="inputEvent"
        @focus="focusEvent"
        @blur="blurEvent"
      ></textarea>
      <slot></slot>
    </div>
    <div v-if="error && !dontShowErrorMsg" class="field__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import setMask from '@/helpers/string/setMask';

import props from './mixins/commonProps';

export default {
  name: 'Field',
  mixins: [props],
  props: {
    textarea: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: ''
    },
    valueWithoutMask: {
      type: Boolean,
      default: false
    },
    checkMaskPosition: {
      type: Function,
      default: function(oldVal, newVal, pos) {
        if (pos === undefined) return newVal.length;

        if (oldVal === newVal) return pos;

        const previousCharPos = pos - 1;
        let offset = 0;
        let i = pos;

        while (
          oldVal[previousCharPos] !== newVal[previousCharPos + offset] &&
          i < newVal.length
        ) {
          offset++;
          i++;
        }

        return pos + offset;
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFocus: false,
      isTabbed: false
    };
  },
  computed: {
    computedPattern() {
      return this.pattern || (this.mask && '[0-9]*');
    },
    field: {
      get() {
        return this.$refs.field;
      },
      set(v) {
        if ([null, undefined].includes(v)) v = '';

        let caretPos = this.field.selectionStart;

        if (this.mask) {
          const beforeMask = v;
          v = setMask(v, this.mask);
          caretPos = this.checkMaskPosition(beforeMask, v, caretPos);
        } else {
          caretPos = v.length;
        }

        if (this.field && this.field.value !== String(v)) {
          this.field.value = v;
          if (caretPos === undefined) caretPos = v.length;
          this.field.setSelectionRange(caretPos, caretPos);
        }

        if (
          this.autoTab &&
          this.mask &&
          !this.isTabbed &&
          this.mask.length === v.length
        ) {
          // @TODO вынести логику отдельно
          const nextField = document.querySelector(
            `input[name="${this.autoTab}"]`
          );
          nextField && nextField.focus();
          this.isTabbed = true;
        }

        if (this.mask && this.valueWithoutMask)
          v = String(v).replace(/[^\d]/g, '');

        this.model !== v && this.$emit('change', v);
      }
    },
    classes() {
      return {
        field_active: this.isFocus || this.model,
        field_error: this.error,
        field_disabled: this.disabled
      };
    },
    inputMode() {
      if (this.mask) {
        return 'numeric';
      }

      return false;
    }
  },
  watch: {
    model(v) {
      this.field = v;
    },
    mask() {
      this.field = this.model;
    }
  },
  mounted() {
    this.field = this.model;
  },
  methods: {
    inputEvent(e) {
      let { value } = e.target;
      this.field = value;
    },
    focusEvent(e) {
      this.isFocus = true;
      this.$emit('focus', e);
    },
    blurEvent(e) {
      this.isFocus = false;
      this.$emit('blur', e);
    }
  }
};
</script>

<style lang="scss">
@import 'field';
</style>
