export default function(val, mask) {
  const elements = {};
  const double = {};
  let length = 0;

  {
    let bias = 0;
    mask.split('').forEach((_, realIndex) => {
      const index = realIndex + bias;
      const item = mask[index];

      switch (item) {
        case undefined: {
          return;
        }
        case '#': {
          break;
        }
        case '[': {
          if (double[mask[index + 2]]) {
            double[mask[index + 2]].push(realIndex - 1);
            elements[realIndex - 1] = mask[index + 4];
          } else {
            double[mask[index + 2]] = [realIndex];
            elements[realIndex] = mask[index + 4];
          }

          bias += 5;
          break;
        }
        default: {
          elements[index] = item;
        }
      }

      length++;
    });
  }

  const interchangeable = Object.keys(double).reduce((acc, key) => {
    const [a, b] = double[key];

    acc[a] = b;
    acc[b] = a;

    return acc;
  }, {});

  {
    const newVal = [];
    let bias = 0;
    let index = 0;

    while (index < length && val[index + bias]) {
      const item = val[index + bias];

      if (elements[index]) {
        if (interchangeable[index]) {
          if (elements[index] === item) {
            delete elements[interchangeable[index]];
            newVal.push(elements[index]);
            index++;
          } else if (index > interchangeable[index]) {
            newVal.push(elements[index]);

            if (item !== elements[index]) {
              bias--;
            }
            index++;
          } else {
            delete elements[index];
          }

          continue;
        }

        if (item !== elements[index]) {
          bias--;
        }

        newVal.push(elements[index]);
        index++;
      } else {
        if (isNaN(+item)) {
          bias++;
        } else {
          newVal.push(item);
          index++;
        }
      }
    }

    return newVal.join('');
  }
}
